import {property, state} from "lit/decorators.js";
import type {TemplateResult} from "lit";
import {html, LitElement, PropertyValues} from "lit";
import {SessionStorage} from "../../../common/clientStorage";
import {resolve} from "../../../container";
import {formSubscriptionEvent, initInputElementEvent, inputElementEvent} from "../formEvents";
import type {InputType} from "../commons";
import {FormPathAggregator} from "../commons";
import type {Validation, ValidationConfig} from "../validations/validations";
import {InputElementType, ValidationError} from "../validations/validations";
import {unsafeSVG} from "lit/directives/unsafe-svg.js";
import type {DirectiveResult} from "lit/directive.js";
import {classMap} from "lit/directives/class-map.js";
import {ValidationFactory} from "../validations/validationFactory";
import type {PropertyMap} from "../../../common/utils/objects";
import {EqualHeightManager} from "../../../page/elements/equalHeight";
import {ManagingResources} from "../../../common/lifetime";
import CHECK_ICON from "../../../../../resources/assets/images/check_tick.svg";
import WARN_ICON from "../../../../../resources/assets/images/warn.svg";
import ERROR_ICON from "../../../../../resources/assets/images/error.svg";

const parseValidation = (validationConfigs: string | null): Validation[] => {
    return (validationConfigs?.parseAsJSON<ValidationConfig<any>[]>() ?? [])
        .map(config => ValidationFactory.from(config));
};

export abstract class InputElement<T extends InputType> extends ManagingResources(LitElement) {

    @property({attribute: "input-id"})
    protected inputId: string;
    @property({attribute: "condition"})
    private condition: string;
    @property({attribute: "validations", converter: parseValidation})
    protected validations: Validation[];

    @state()
    protected value: T;
    @state()
    protected isValidatable: boolean;
    @state()
    protected errors: string[];
    @state()
    protected warnings: string[];
    @state()
    protected required: boolean;
    @state()
    protected type: InputElementType | null;
    @state()
    protected customAttributes: PropertyMap<string>;

    protected path: string[];
    private offset: number;

    protected constructor(
        private emptyValue: T,
        private equalHeightManager: EqualHeightManager = resolve(EqualHeightManager),
        private sessionStorage: SessionStorage = resolve(SessionStorage)
    ) {
        super();
        this.path = [];
        this.validations = [];
        this.isValidatable = false;
        this.errors = [];
        this.warnings = [];
        this.required = false;
        this.type = null;
        this.customAttributes = {};
    }

    protected abstract preset(): T | undefined;

    public abstract focusInput(): void;

    public connectedCallback(): void {
        super.connectedCallback();
        if (this.condition) {
            this.dispatchEvent(formSubscriptionEvent(this.condition, result => this.changeCondition(result)));
        }
        const formPathAggregator = new FormPathAggregator(this.inputId, path => this.afterSubscription(path));
        this.dispatchEvent(initInputElementEvent(formPathAggregator, this.validate));
    }

    protected updated(changedProperties: PropertyValues): void {
        const element = this.shadowRoot!.querySelector<HTMLElement>(".error-messages")!;
        if (element) {
            this.equalHeightManager.registerResponsive(element, breakpoint => {
                if (!this.offset || breakpoint) {
                    this.offset = this.getBoundingClientRect().top;
                }
                return "error-messages-" + this.offset;
            });
        }
    }

    private afterSubscription(path: string[]): void {
        this.path = path;
        this.value = this.sessionStorage.fetchFromPath<T>(path) ?? this.preset() ?? this.emptyValue;
        this.isValidatable = !!this.value;
        this.updateValidState(this.value);
        this.sendInputEvent(this.value);
    }

    protected getFullId(): string {
        return this.path.join("-");
    }

    protected updateValue(inputValue: T): void {
        if (this.value === inputValue) {
            return;
        }
        this.value = inputValue;
        this.updateValidState(inputValue);
        this.isValidatable = true;
        this.publishValue(inputValue);
    }

    protected formId(): string {
        return this.path[0];
    }

    protected publishValue(inputValue: T): void {
        this.sessionStorage.saveInPath(this.formId(), this.path.slice(1), inputValue);
        this.sendInputEvent(inputValue);
    }

    protected renderValidationMessages(): TemplateResult {
        const texts = this.isInErrorState() || this.isInWarningState()
            ? this.renderValidationTexts()
            : null;

        return html`
            <div class="error-messages">
                ${texts}
            </div>
        `;
    }

    private renderValidationTexts(): TemplateResult {
        return html`
            ${(this.renderErrors())}
            ${(this.renderWarnings())}
        `;
    }

    private renderErrors(): TemplateResult[] {
        return this.errors.map(msg => this.errorMessage(msg));
    }

    private renderWarnings(): TemplateResult[] {
        return this.warnings.map(msg => this.warningMessage(msg));
    }

    protected renderValidationIcon(): TemplateResult | null {
        const icon = this.renderIcon();
        if (!icon) {
            return null;
        }

        return html`
            <div class="validation-icon">${icon}</div>`;
    }

    protected basicClassMap(): DirectiveResult {
        return classMap({
            valid: this.isInValidState(),
            warning: this.isInWarningState(),
            invalid: this.isInErrorState(),
            required: this.required
        });
    }

    private renderIcon(): TemplateResult | null {
        if (this.isInErrorState()) {
            return html`${unsafeSVG(ERROR_ICON)}`;
        }
        if (this.isInWarningState()) {
            return html`${unsafeSVG(WARN_ICON)}`;
        }
        if (this.isValidatable) {
            return html`${unsafeSVG(CHECK_ICON)}`;
        }
        return null;
    }

    private validate = (silent: boolean = false): void => {
        if (this.hidden) {
            return;
        }
        if (!silent) {
            this.isValidatable = true;
            this.updateValidState(this.value);
        }
        if (!this.isValid()) {
            throw new ValidationError(this);
        }
    };

    private sendInputEvent(inputValue: T): void {
        const customValues = this.valueEnhancements(inputValue);
        this.dispatchEvent(inputElementEvent(this.path, inputValue, customValues));
    }

    protected valueEnhancements(inputValue: T): Map<string, string> {
        return new Map();
    }

    protected changeCondition(result: boolean): void {
        if (result) {
            this.expand();
        } else {
            this.collapse();
        }
    }

    private expand(): void {
        if (this.hidden) {
            this.hidden = false;
            this.publishValue(this.value);
        }
    }

    private collapse(): void {
        if (!this.hidden) {
            this.hidden = true;
            this.publishValue(this.emptyValue);
        }
    }

    protected isValid(): boolean {
        return this.errors.isEmpty();
    }

    protected hasWarning(): boolean {
        return this.warnings.isNotEmpty();
    }

    protected updateValidState(value: T): void {
        this.errors = [];
        this.warnings = [];
        this.validations.forEach(validation => validation.accept(this, value));
    }

    protected labelSuffix(): TemplateResult {
        return html`
            <span class="label-suffix">
                <slot name="i-button"></slot>
            </span>`;
    }

    protected errorMessage(message: string): TemplateResult {
        return html`
            <div class="error-message">${message}</div>`;
    }

    protected warningMessage(message: string): TemplateResult {
        return html`
            <div class="warning-message">${message}</div>`;
    }

    private isInValidState(): boolean {
        return this.isValidatable && this.isValid();
    }

    private isInErrorState(): boolean {
        return this.isValidatable && !this.isValid();
    }

    private isInWarningState(): boolean {
        return this.isValidatable && this.hasWarning() && !this.isInErrorState();
    }

    public setRequired(required: boolean): void {
        this.required = required;
    }

    public setType(type: InputElementType): void {
        this.type = type;
    }

    public addMessage(msg: string, onlyWarning: boolean): void {
        if (onlyWarning) {
            this.warnings.push(msg);
        } else {
            this.errors.push(msg);
        }
    }

    public addCustomAttribute(attr: string, value: string): void {
        this.customAttributes[attr] = value;
    }
}