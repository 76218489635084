import {customElement, property, query, state} from "lit/decorators.js";
import type {TemplateResult} from "lit";
import {html} from "lit";
import {InputElement} from "./inputElement";
import Styles from "./uploadField.lit.scss";
import {UploadDeleteRequest, UploadRequest, UploadResponseError, UploadService} from "../uploadService";
import {resolve} from "../../../container";
import {LanguagesService} from "../../../common/languages";
import {EOP_ERRORS, Promises, schedule} from "../../../common/utils/promises";
import type {PropertyMap} from "../../../common/utils/objects";
import {Dictionary} from "../../../page/elements/dictionary";
import {DELETE_UPLOAD_EVENT, DRAG_DROP_UPLOAD_EVENT, FormDeleteUploadEvent, FormDragDropUploadEvent} from "../formEvents";
import {EopUploadedFile} from "./uploadedFile";
import {classMap} from "lit/directives/class-map.js";

export type UploadData = {
    key: string;
    name: string;
    size: number;
    type: string;
};

enum InputState {
    WAITING_FOR_INPUT, UPLOADING, MAX_UPLOADS_REACHED
}

const HTTP_ERROR_TEXT_KEYS: PropertyMap = {
    413: "HTTP_ERROR_413",
    415: "HTTP_ERROR_415"
};

@customElement("eop-upload-field")
export class EopUploadField extends InputElement<UploadData[] | null> {

    public static readonly styles = Styles;

    @property({attribute: "config-id"})
    private configId: string;
    @property({attribute: "label"})
    public label: string;
    @property({attribute: "max-uploads"})
    public maxUploads: number;
    @property({attribute: "supported-file-types"})
    public supportedFileTypes: string;
    @state()
    private uploadedFiles: EopUploadedFile[];
    @state()
    private uploading: boolean;
    @query(".input-element")
    private input: HTMLInputElement;

    public constructor(
        private uploadService: UploadService = resolve(UploadService),
        private languageService: LanguagesService = resolve(LanguagesService),
        private promises: Promises = resolve(Promises)
    ) {
        super(null);
        this.maxUploads = 1;
        this.uploadedFiles = [];
        this.uploading = false;
        this.addEventListener(DELETE_UPLOAD_EVENT, event => {
            const eventData = (event as FormDeleteUploadEvent).detail;
            this.deleteFile(eventData.fileKey);
            event.stopPropagation();
        });

        this.addEventListener(DRAG_DROP_UPLOAD_EVENT, event => {
            const eventData = (event as FormDragDropUploadEvent).detail;
            this.uploadFiles(eventData.inputFiles);
            event.stopPropagation();
        });

    }

    public render(): TemplateResult {
        return html`
            <div class="input-element ${this.basicClassMap()}">
                <label class="label" ?required=${this.required}>
                    <span class="label-text">${this.label}</span>
                    ${this.labelSuffix()}
                </label>
                ${this.renderUploadButton()}
                ${this.renderValidationIcon()}
                ${this.uploadedFiles}
                ${this.renderDragAndDropArea()}
            </div>
            ${this.renderValidationMessages()}
            <eop-overlay-spinner event="upload-delete"></eop-overlay-spinner>
        `;
    }

    public connectedCallback(): void {
        super.connectedCallback();

        Array.from(this.value ?? []).forEach(file => {
            const uploadedFile = new EopUploadedFile(file.name, file.size);
            uploadedFile.updateProgress(100);
            uploadedFile.key = file.key;
            this.uploadedFiles.push(uploadedFile);
        });
    }

    public disconnectedCallback(): void {
        super.disconnectedCallback();
        this.maxUploads = 1;
        this.uploadedFiles = [];
        this.uploading = false;
    }

    public focusInput(): void {
        this.input.tabIndex = -1;
        this.input.focus();
    }

    protected preset(): undefined {
        return undefined;
    }

    private renderUploadButton(): TemplateResult | null {
        const classes = {disabled: !this.isWaitingForInput()};

        return html`
            <button class="upload-button" tabindex="-1">
                <label @keydown=${this.redirectEnterToClick} class="secondary ${classMap(classes)}" tabindex="0" for=${this.getFullId()}>
                    <eop-msg key="SELECT_FILE"></eop-msg>
                </label>
                <input
                        id=${this.getFullId()}
                        type="file"
                        @click=${this.resetInputValue}
                        @change=${this.change}
                        ?required=${this.required}
                        accept=${this.supportedFileTypesPrefacedWithDot()}
                        ?multiple=${this.maxUploads > 1}
                        ?disabled=${!this.isWaitingForInput()}
                >
            </button>`;
    }

    private resetInputValue(event: Event): void {
        if (event.target) {
            (event.target as HTMLInputElement).value = ""; // otherwise you cannot upload the same file, after deleting it
        }
    }

    private renderDragAndDropArea(): TemplateResult | null {
        return html`
            <eop-upload-drag-drop-area ?disabled=${!this.isWaitingForInput()}></eop-upload-drag-drop-area>`;
    }

    private supportedFileTypesPrefacedWithDot(): string {
        if (!this.supportedFileTypes || this.supportedFileTypes.length === 0) {
            return "";
        }
        return this.supportedFileTypes.split(",")
            .map(type => "." + type)
            .join(",");
    }

    private inputState(): InputState {
        if (this.uploading) {
            return InputState.UPLOADING;
        }
        if ((this.value?.length ?? 0) < this.maxUploads) {
            return InputState.WAITING_FOR_INPUT;
        } else {
            return InputState.MAX_UPLOADS_REACHED;
        }
    }

    private isWaitingForInput(): boolean {
        return this.inputState() === InputState.WAITING_FOR_INPUT;
    }

    private async change(event: Event): Promise<void> {
        const inputFiles = (event.target as HTMLInputElement).attachedFiles();
        await this.uploadFiles(inputFiles);
    }

    private async uploadFiles(inputFiles: File[]): Promise<void> {
        const currentFiles = Array.from(this.value ?? []);
        inputFiles.removeEvery(file => currentFiles.some(f => f.name === file.name));

        if (inputFiles.isEmpty()) {
            return;
        }

        this.uploading = true;

        this.validateFiles(currentFiles.concat(inputFiles
            .map(file => ({name: file.name, key: "tempKey", size: file.size, type: file.type}))));

        if (!this.isValid()) {
            this.isValidatable = true;
            this.uploading = false;
            return;
        }
        this.isValidatable = false;

        const uploadedFiles: UploadData[] = [];
        await schedule(Promise.all(inputFiles.map(async file => {
            const uploadedFile = await this.uploadFile(file);
            if (uploadedFile.key) {
                uploadedFiles.push(uploadedFile);
            }
        }))).as("all-uploaded");

        if (uploadedFiles.isNotEmpty()) {
            this.updateValue(currentFiles.concat(uploadedFiles));
        }

        this.uploading = false;
    }

    private async uploadFile(file: File): Promise<UploadData> {
        const uploadedFile = new EopUploadedFile(file.name, file.size);
        this.uploadedFiles.push(uploadedFile);
        const uploadRequest = new UploadRequest(
            file,
            this.configId,
            this.formId(),
            (progress) => uploadedFile.updateProgress(progress),
            this.languageService.activeLanguageId()
        );

        await this.uploadService.upload(uploadRequest)
            .then(response => {
                uploadedFile.key = response.uploadKey;
            })
            .catch(e => {
                if (!(e instanceof UploadResponseError)) {
                    throw e;
                }
                this.uploadedFiles.removeAll(uploadedFile);
                this.handleBackendError(e);
            })
            .catch(EOP_ERRORS);

        return {name: file.name, key: uploadedFile.key, size: file.size, type: file.type};
    }

    private clear(fileKey: string): void {
        const files = Array.from(this.value ?? []);
        files.removeEvery(file => file.key === fileKey);
        this.uploadedFiles.removeEvery(file => file.key === fileKey);

        if (files.isEmpty()) {
            this.updateValue(null);
        } else {
            this.updateValue(files);
        }
    }

    private async deleteFile(fileKey: string): Promise<void> {
        const deleteRequest = new UploadDeleteRequest(fileKey, this.configId, this.formId(), this.languageService.activeLanguageId());
        const spinnerForDelete = this.promises.decoratorFor("upload-delete")(this.uploadService.delete(deleteRequest));
        await schedule(spinnerForDelete).as("upload-delete");
        this.clear(fileKey);
    }

    private validateFiles(files: UploadData[]): void {
        this.errors = this.validations
            .filter(validation => !validation.isValid(files))
            .map(validation => validation.errorText);
    }

    private handleBackendError(response: UploadResponseError): void {
        this.isValidatable = true;
        const msg = Dictionary.of(this).translate(HTTP_ERROR_TEXT_KEYS[response.status] ?? "HTTP_ERROR_UNKNOWN");
        this.errors = [msg];
    }

    private redirectEnterToClick(e: Event): void {
        if (e instanceof KeyboardEvent && e.key === "Enter") {
            (e.target as HTMLElement).click();
        }
    }
}

