import {customElement, property, query} from "lit/decorators.js";
import {html, TemplateResult} from "lit";
import Styles from "./textAreaField.lit.scss";
import {InputElement} from "./inputElement";

@customElement("eop-text-area-field")
export class EopTextAreaField extends InputElement<string> {

    public static readonly styles = Styles;

    @property({attribute: "label"})
    public label: string;
    @query("textarea")
    private textarea: HTMLTextAreaElement;

    public constructor() {
        super("");
    }

    public render(): TemplateResult {
        return html`
            <div class="input-element ${this.basicClassMap()}">
                <textarea
                        name=${this.getFullId()}
                        rows="5"
                        .value=${this.value}
                        ?filled=${this.value}
                        ?required=${this.required}
                        @change=${this.change}
                        @input=${this.onInput}
                >
                </textarea>
                <label>
                    <span class="label-text">${this.label}</span>
                    ${this.labelSuffix()}
                </label>
                ${this.renderValidationIcon()}
            </div>
            ${(this.renderValidationMessages())}
        `;
    }

    public focusInput(): void {
        this.textarea.focus();
    }

    protected preset(): string | undefined {
        return this.getAttribute("preset") ?? undefined;
    }

    private change(event: Event): void {
        this.updateValue((event.target as HTMLTextAreaElement).value);
    }

    private onInput(event: Event): void {
        if (this.isValidatable) {
            this.updateValidState((event.target as HTMLTextAreaElement).value);
        }
    }
}