import {InputElementType, Validation, ValidationConfig} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";
import {LocalDate} from "../../../common/utils/dates/localDate";
import {dateExpressionFrom} from "../../../common/utils/dates/parseDate";

export type DateRangeConfig = ValidationConfig<{
    id: string;
    startDate?: string;
    endDate?: string;
}>;

export class DateRange extends Validation {

    private constructor(
        messageText: string,
        onlyWarning: boolean,
        private startDate?: string,
        private endDate?: string
    ) {
        super(messageText, onlyWarning);
    }

    public static from(config: DateRangeConfig): DateRange {
        return new DateRange(config.message, config.onlyWarning, config.validation.startDate, config.validation.endDate);
    }

    protected valid(value: unknown): boolean {
        if (value === "") {
            return true;
        }

        const dateFromExpression = LocalDate.fromDateExpression(value as string);
        if (!dateFromExpression.isValid()) {
            return false;
        }
        if (this.endDate && LocalDate.fromDateExpression(this.endDate).isBefore(dateFromExpression)) {
            return false;
        }
        if (this.startDate && LocalDate.fromDateExpression(this.startDate).isAfter(dateFromExpression)) {
            return false;
        }
        return true;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value: T): void {
        inputElement.setType(InputElementType.DATE);
        if (this.endDate) {
            inputElement.addCustomAttribute("max", this.toDateAttribute(this.endDate));
        }
        if (this.startDate) {
            inputElement.addCustomAttribute("min", this.toDateAttribute(this.startDate));
        }

        if (!this.isValid(value)) {
            inputElement.addMessage(this.errorText, false);
        }
        if (this.isWarning(value)) {
            inputElement.addMessage(this.errorText, true);
        }
    }

    private toDateAttribute(relativeOrAbsoluteDate: string): string {
        return dateExpressionFrom(relativeOrAbsoluteDate).toLocalDate().toHtml5DateString();
    }
}