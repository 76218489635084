import {customElement, property} from "lit/decorators.js";
import {html, LitElement, TemplateResult} from "lit";
import Styles from "./selectOption.lit.scss";
import {eopCustomEvent} from "../../../../common/eventBus";

export const SELECT_OPTION_EVENT = "selectOption";

@customElement("eop-select-option")
export class EopSelectOption extends LitElement {

    public static readonly styles = Styles;

    @property()
    public value: string;
    @property()
    public label: string;
    @property({attribute: true, type: Boolean})
    public selected: boolean = false;

    public render(): TemplateResult {
        return html`
            <div @click=${this.sendSelectEvent} class="custom-option">${this.label}</div>`;
    }

    private sendSelectEvent(event: Event): void {
        event.stopPropagation();
        this.dispatchEvent(selectOptionEvent(this.value, this.label));
    }
}

export type SelectOptionEvent = CustomEvent<{ value: string; label: string }>;

export function selectOptionEvent(value: string, label: string): SelectOptionEvent {
    return eopCustomEvent(SELECT_OPTION_EVENT, {value: value, label: label});
}