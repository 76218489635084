import {InputElementType, Validation, ValidationConfig} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";
import {LocalDate} from "../../../common/utils/dates/localDate";

export type MinAgeConfig = ValidationConfig<{
    id: string;
    minAge?: string;
}>;

export class MinAge extends Validation {

    private constructor(messageText: string, onlyWarning: boolean, public minAge?: string) {
        super(messageText, onlyWarning);
    }

    public static from(config: MinAgeConfig): MinAge {
        return new MinAge(config.message, config.onlyWarning, config.validation.minAge);
    }

    protected valid(value: unknown): boolean {
        if (value === "") {
            return true;
        }

        const dateFromExpression = LocalDate.fromDateExpression(value as string);
        if (!dateFromExpression.isValid()) {
            return false;
        }
        if (this.minAge) {
            const minAgeNumber = parseInt(this.minAge);
            return dateFromExpression.calculateAge() >= minAgeNumber;
        }
        return true;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        inputElement.setType(InputElementType.DATE);
        if (!this.isValid(value)) {
            inputElement.addMessage(this.errorText, false);
        }
        if (this.isWarning(value)) {
            inputElement.addMessage(this.errorText, true);
        }
    }
}