import Styles from "./dateField.lit.scss";
import {InputElement} from "./inputElement";
import {customElement, property, query} from "lit/decorators.js";
import {InputElementType} from "../validations/validations";
import {html, TemplateResult} from "lit";
import {LocalDate} from "../../../common/utils/dates/localDate";
import {LocatedDates} from "../../../common/utils/dates/locatedDates";
import {resolve} from "../../../container";
import type {DirectiveResult} from "lit/directive.js";
import {directive} from "lit/directive.js";
import {SpreadAttributes} from "../../../common/directives/spreadAttributes";
import type {PropertyMap} from "../../../common/utils/objects";

@customElement("eop-date-field")
export class EopDateField extends InputElement<string> {

    public static readonly styles = Styles;

    private locatedDates: LocatedDates = resolve(LocatedDates);
    @property({attribute: "label"})
    public label: string;
    @query("input")
    private input: HTMLInputElement;

    public constructor(private spreadAttributes: (attributes: PropertyMap<string>) => DirectiveResult = directive(SpreadAttributes)) {
        super("");
        this.type = InputElementType.DATE;
    }

    public render(): TemplateResult {
        return html`
            <div class="input-element ${this.basicClassMap()}">
                <label>
                    <span class="label-text">${this.label}</span>
                    ${this.labelSuffix()}
                </label>
                <input
                        name=${this.getFullId()}
                        .value=${this.value}
                        ?filled=${this.value}
                        @change=${this.change}
                        @input=${this.onInput}
                        type=${this.type}
                        ?required=${this.required}
                        ${this.spreadAttributes(this.customAttributes)}
                >
                ${this.renderValidationIcon()}
            </div>
            ${(this.renderValidationMessages())}
        `;
    }

    public focusInput(): void {
        this.input.focus();
    }

    protected preset(): string | undefined {
        return this.getAttribute("preset") ?? undefined;
    }

    protected valueEnhancements(inputValue: string): Map<string, string> {
        const date = LocalDate.fromDateExpression(inputValue).toDate();
        if (date) {
            return new Map([["date", this.locatedDates.toLocalDateString(date)]]);
        } else {
            return new Map();
        }
    }

    private change(event: Event): void {
        const value = (event.target as HTMLInputElement).value;
        this.updateValue(value);
    }

    private onInput(event: Event): void {
        if (this.isValidatable) {
            this.updateValidState((event.target as HTMLInputElement).value);
        }
    }
}