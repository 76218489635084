import {Validation, ValidationConfig} from "./validations";
import type {InputType} from "../commons";
import type {InputElement} from "../components/inputElement";
import type {UploadData} from "../components/uploadField";

export type FileCountConfig = ValidationConfig<{
    id: string;
    maxUploads: number;
}>;

export class MaxUploads extends Validation {
    private constructor(messageText: string, onlyWarning: boolean, private maxUploads: number) {
        super(messageText, onlyWarning);
    }

    public static from(config: FileCountConfig): MaxUploads {
        return new MaxUploads(config.message, config.onlyWarning, config.validation.maxUploads);
    }

    protected valid(files: UploadData[]): boolean {
        return !files || files.length <= this.maxUploads;
    }

    public accept<T extends InputType>(inputElement: InputElement<T>, value?: T): void {
        if (!this.isValid(value)) {
            inputElement.addMessage(this.errorText, false);
        }
        if (this.isWarning(value)) {
            inputElement.addMessage(this.errorText, true);
        }
    }
}